import React, { useState, useEffect } from 'react';

import Spinner from '../Spinner';
import Spacer from '../Spacer';
import Text from '../Text';

import { Container, Loader, Img, Error } from './styles';

export interface ImageLoaderProps {
  url: string;
  loadingMessage?: string;
  errorMessage?: string;
}

const ImageLoader: React.FC<ImageLoaderProps> = React.memo(
  ({ url, loadingMessage, errorMessage }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [loadedUrl, setLoadedUrl] = useState<string>();

    useEffect(() => {
      setLoading(true);
      setError(false);
      setLoadedUrl(undefined);

      const image = new Image();

      image.onload = () => {
        setLoading(false);
        setLoadedUrl(url);
      };

      image.onerror = () => {
        setLoading(false);
        setError(true);
      };

      image.src = url;
    }, [url]);

    return (
      <Container>
        {loading && url && (
          <Loader>
            <Spinner size={2} />
            <Spacer small={{ spacing: 'tiny' }} />
            <Text as="span">{loadingMessage}</Text>
          </Loader>
        )}
        {error && url && <Error>{errorMessage}</Error>}

        {url && <Img url={loadedUrl} />}
      </Container>
    );
  }
);

export default ImageLoader;
