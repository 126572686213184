import React from 'react';

import { Column, Columns } from '../Columns';
import Spacer from '../Spacer';
import Text from '../Text';
import SocialShareButton, { SocialPlatform } from '../SocialShareButton';

export interface SocialSharePanelProps {
  shareUrl: string;
  tweets: string[];
}

const SocialSharePanel: React.FC<SocialSharePanelProps> = ({
  shareUrl,
  tweets,
}) => (
  <React.Fragment>
    <Text as="h4" weight={500} noMargin>
      Share on social media
    </Text>
    <Spacer small={{ spacing: 'medium' }} />
    <Columns small={{ spacing: 'small' }}>
      {[
        SocialPlatform.Facebook,
        SocialPlatform.Twitter,
        SocialPlatform.LinkedIn,
      ].map((socialPlatform) => (
        <Column key={socialPlatform} small={{ spacing: 'small', size: 1 / 3 }}>
          <SocialShareButton
            platform={socialPlatform}
            shareUrl={shareUrl}
            tweets={tweets}
          />
        </Column>
      ))}
    </Columns>
  </React.Fragment>
);

export default SocialSharePanel;
