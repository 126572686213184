import React, { useCallback } from 'react';
import sample from 'lodash/sample';
import { SocialButton } from './styles';

export enum SocialPlatform {
  Facebook = 'facebook',
  Twitter = 'twitter',
  LinkedIn = 'linkedin',
}
export interface SocialShareButtonProps {
  platform: SocialPlatform;
  shareUrl: string;
  tweets?: string[];
}

const SocialShareButton: React.FC<SocialShareButtonProps> = React.memo(({
  platform,
  shareUrl,
  tweets,
}) => {
  const handleSocialShare = useCallback(() => {
    const tweet = sample(tweets) as string;

    const templates = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      twitter: `https://twitter.com/intent/tweet?source=webclient&text=${encodeURIComponent(tweet).replace('__LINK__', shareUrl)}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`,
    };

    const link = templates[platform];

    const height = 570;
    const width = 520;
    const top = (window.innerHeight / 2) - (height / 2);
    const left = (window.innerWidth / 2) - (width / 2);

    window.open(link, '_blank', `location=yes,height=${height},width=${width},scrollbars=yes,status=yes,top=${top},left=${left}`);
  }, [platform]);

  const getPlatformLabel = () => {
    if (platform === SocialPlatform.Facebook) return 'Facebook';
    if (platform === SocialPlatform.Twitter) return 'Twitter';
    if (platform === SocialPlatform.LinkedIn) return 'LinkedIn';
    return '';
  };

  return (
    <SocialButton
      platform={platform}
      label={getPlatformLabel()}
      onClick={handleSocialShare}
    />
  );
});

export default SocialShareButton;
