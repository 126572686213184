import Styled from 'styled-components';
import Media from '@uizard-io/styled-media-query';

import mobileFrame from '../../images/iphone.png';
import desktopFrame from '../../images/macbook.png';

export const Container = Styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
`;

export const Content = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const DesktopDeviceContainer = Styled.div`
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 78%;
  height: 0;
  padding-bottom: calc(78% * (900 / 1440));
`;

export const DesktopDevice = Styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const MobileDeviceContainer = Styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 0;
  padding-bottom: calc(90% * (667 / 375));
`;

export const MobileDevice = Styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const MobileFrame = Styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  top: 6rem;
  right: 0;
  width: 24rem;
  height: 49rem;
  max-width: 28%;
  max-height: 75%;
  background-image: url(${mobileFrame});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${Media.greaterThan('medium')`
    top: 9rem;
  `}

  ${Media.greaterThan('large')`
    width: 24rem;
    height: 49rem;
    top: 10rem;
  `}
`;

export const DesktopFrame = Styled.div`
  position: absolute;
  top: 4rem;
  left: 0;
  width: 92.1rem;
  height: 60rem;
  max-width: 100%;
  max-height: 88%;
  background-image: url(${desktopFrame});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${Media.greaterThan('large')`
    width: 92.1rem;
    height: 60rem;
  `}
`;