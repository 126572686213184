import React, { useState, useEffect, useMemo } from 'react';
import shuffle from 'lodash/shuffle';

import {
  Container,
  Messages,
  Message,
} from './styles';

export const DEFAULT_MESSAGES = [
  'Herding the unicorns',
  'Feeding the engineers',
  'Booting the server',
  'Summoning the AI',
  'Sketching wireframes',
  'Counting pixels',
  'Learning colors',
  'Extracting design components',
  'Deploying your workspace',
  'Wizards converting wireframes',
  'Rehearsing wizard spells',
  'Making magic',
  'You\'re a Uizard, Harry',
];

export interface MessageLoopLoaderProps {
  messages?: string[];
}

const MessageLoopLoader: React.FC<MessageLoopLoaderProps> = ({
  messages = DEFAULT_MESSAGES,
}) => {
  const [index, setIndex] = useState(0);
  const shuffledMessages = useMemo(() => shuffle(messages), [messages]);
  const numOfMessages = shuffledMessages.length;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndex((index + 1) % numOfMessages);
      shuffledMessages.push(shuffledMessages[index]);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [index, numOfMessages, shuffledMessages]);

  return (
    <Container>
      <Messages>
        {shuffledMessages.map((message, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Message key={i} index={index}>
            {message}
          </Message>
        ))}
      </Messages>
    </Container>
  );
};


export default MessageLoopLoader;
