import Styled from 'styled-components/macro';
import { transparentize } from 'polished';

export interface MessageProps {
  index: number;
}

export const Container = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  z-index: 999999;
`;

export const Messages = Styled.div`
  position: relative;
  height: 12rem;
  overflow: hidden;
  padding: 1rem 0;
  text-align: center;
  transform: translateZ(0);

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.white};
    background: linear-gradient(
      ${props => props.theme.colors.white} 0%, 
      ${props => transparentize(1, props.theme.colors.white)} 50%,
      ${props => props.theme.colors.white} 100%);
    }
`;

export const Message = Styled.div<MessageProps>`
  position: relative;
  font-size: 1.6rem;
  height: 4rem;
  top: ${props => -(props.index * 4)}rem;
  line-height: 1.25;
  text-align: center;
  transition: top 300ms ease-out;
`;
