import React from 'react';

import ScreenPreview from '../../components/ScreenPreview';

import {
  Container,
  Content,
  DesktopDeviceContainer,
  DesktopDevice,
  MobileDeviceContainer,
  MobileDevice,
  MobileFrame,
  DesktopFrame,
} from './styles';

export interface ResultPreviewProps {
  desktopUrl: string;
  mobileUrl: string;
}

const ResultPreview: React.FC<ResultPreviewProps> = React.memo(
  ({ mobileUrl, desktopUrl }) => (
    <Container>
      <Content>
        <DesktopFrame>
          <DesktopDeviceContainer>
            <DesktopDevice>
              <ScreenPreview
                url={desktopUrl}
                loadingMessage="Loading preview..."
                errorMessage="An error occurred while loading the preview 😱 Please try again"
              />
            </DesktopDevice>
          </DesktopDeviceContainer>
        </DesktopFrame>

        <MobileFrame>
          <MobileDeviceContainer>
            <MobileDevice>
              <ScreenPreview
                url={mobileUrl}
                loadingMessage="Loading preview..."
                errorMessage="An error occurred while loading the preview 😱 Please try again"
              />
            </MobileDevice>
          </MobileDeviceContainer>
        </MobileFrame>
      </Content>
    </Container>
  )
);

export default ResultPreview;
