import Styled from 'styled-components';
import Media from '@uizard-io/styled-media-query';

export const ButtonGroup = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -0.5rem;

  & > * {
    margin: 0 0.5rem;
  }

  ${Media.lessThan('medium')`
    display: block;
    margin: 0;

    & > * {
      width: 100%;
      margin: 0;
      margin-bottom: 1rem;
    }
  `}
`