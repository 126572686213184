import Styled from 'styled-components/macro';
import { darken } from 'polished';

import Button, { ButtonProps } from '../Button';

import { SocialPlatform } from '.';

export interface SocialButtonProps extends ButtonProps {
  platform: SocialPlatform;
}

const getPlatformColor = (platform: SocialPlatform) => {
  if (platform === SocialPlatform.Facebook) return '#4267b2';
  if (platform === SocialPlatform.Twitter) return '#1da1f2';
  if (platform === SocialPlatform.LinkedIn) return '#2867b2';
  return '#FFFFFF';
};

export const SocialButton = Styled<React.FC<SocialButtonProps>>(Button)`
  background-color: ${props => getPlatformColor(props.platform)} !important;
  border: none !important;
  color: white !important;

  &:hover {
    background-color: ${props => darken(0.1, getPlatformColor(props.platform))} !important;
    border: none;
    color: white;
  }

  &:active {
    background-color: ${props => darken(0.1, getPlatformColor(props.platform))} !important;
    border: none;
    color: white;
  }
`;
