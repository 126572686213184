import Styled from 'styled-components';
import Media from '@uizard-io/styled-media-query';

export const ResultContainer = Styled.section`
  max-width: 100%;
  margin: 0 auto;

  ${Media.greaterThan('large')`
    width: 100rem;
    margin-bottom: -10rem;
  `}
`;
