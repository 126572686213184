/* eslint-disable indent */
import Styled from 'styled-components/macro';
import Media from '@uizard-io/styled-media-query';

import { Theme } from '../../theme';

import {
  ColumnConfigurationType,
  ColumnProps,
  ColumnsConfigurationType,
  ColumnsProps,
} from './index';

interface StyledColumnProps extends ColumnProps {
  theme: Theme;
}

interface StyledColumnsProps extends ColumnsProps {
  theme: Theme;
}

const columnsProps = (theme: Theme, configuration: ColumnsConfigurationType) => {
  const spacing = configuration.spacing ? theme.spacing[configuration.spacing] : '0';
  const halfSpacing = parseFloat(spacing) / 2;

  return `
    ${halfSpacing ? `margin: 0 -${halfSpacing}rem;` : ''}
  `;
};

export const Columns = Styled.div<ColumnsProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${Media.lessThan('medium')`
    ${(props: StyledColumnsProps) => columnsProps(props.theme, {
      ...(props.small || {}),
    })}
  `}

  ${Media.greaterThan('medium')`
    ${(props: StyledColumnsProps) => columnsProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
    })}
  `}

  ${Media.greaterThan('large')`
    ${(props: StyledColumnsProps) => columnsProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
      ...(props.large || {}),
    })}
  `}

  ${Media.greaterThan('huge')`
    ${(props: StyledColumnsProps) => columnsProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
      ...(props.large || {}),
      ...(props.huge || {}),
    })}
  `}
`;

const columnProps = (theme: Theme, configuration: ColumnConfigurationType) => {
  const spacing = configuration.spacing ? theme.spacing[configuration.spacing] : '0';
  const halfSpacing = parseFloat(spacing) / 2;

  const paddingBottom = configuration.paddingBottom ? theme.spacing[configuration.paddingBottom] : '0';

  return `
    display: flex;
    flex-direction: ${configuration.flexDirection || 'column'};
    ${configuration.justifyContent ? `justify-content: ${configuration.justifyContent};` : ''}
    ${configuration.alignItems ? `align-items: ${configuration.alignItems};` : ''}
    ${configuration.size ? `width: calc(100% * ${configuration.size});` : ''}
    ${halfSpacing ? `padding-left: ${halfSpacing}rem;` : ''}
    ${halfSpacing ? `padding-right: ${halfSpacing}rem;` : ''}
    ${paddingBottom ? `padding-bottom: ${paddingBottom};` : ''}
  `;
};

export const Column = Styled.div<ColumnProps>`
  ${Media.lessThan('medium')`
    ${(props: StyledColumnProps) => columnProps(props.theme, {
      ...(props.small || {}),
    })}
  `}

  ${Media.greaterThan('medium')`
    ${(props: StyledColumnProps) => columnProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
    })}
  `}

  ${Media.greaterThan('large')`
    ${(props: StyledColumnProps) => columnProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
      ...(props.large || {}),
    })}
  `}

  ${Media.greaterThan('huge')`
    ${(props: StyledColumnProps) => columnProps(props.theme, {
      ...(props.small || {}),
      ...(props.medium || {}),
      ...(props.large || {}),
      ...(props.huge || {}),
    })}
  `}
`;
