import Styled from 'styled-components';

export interface ImageProps {
  url?: string;
}

export const Container = Styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.white};
`;

export const Loader = Styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.colors.mediumGray};
`;

export const Error = Styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${props => props.theme.colors.danger};
`;

export const Img = Styled.div<ImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
`;
