import React, { useState } from 'react';
import { PageProps } from 'gatsby';

import Button from '../Button';
import { Column, Columns } from '../Columns';
import Text from '../Text';
import MessageLoopLoader from '../MessageLoopLoader';
import ResultPreview from '../ResultPreview';
import SocialSharePanel from '../SocialSharePanel';
import ButtonGroup from '../ButtonGroup';
import Spacer from '../Spacer';

import { useStyleExtraction } from './hooks/useStyleExtraction';

import { ResultContainer } from './styles';
import Icon from '../Icon';

export interface ResultProps {
  location: PageProps['location'];
}

const Result: React.FC<ResultProps> = React.memo(({ location }) => {
  const [buttonState, setButtonState] = useState<'initial' | 'design'>('initial');
  const { data, loading, error } = useStyleExtraction(location);

  return (
    <React.Fragment>
      {(loading || data?.status.state === 'running') && <MessageLoopLoader />}

      {(data?.status.state === 'error' || error) && (
        <React.Fragment>
          <div>
            Oh oh! An error occurred{' '}
            <span role="img" aria-label="Face Screaming In Fear">
              😱
            </span>
            Please try again
          </div>
          <br />
          <Button
            as="link"
            label="Try again"
            variant="secondary"
            type="button"
            url="/"
          />
        </React.Fragment>
      )}

      {data?.status.state === 'complete' && (
        <div>
          <Columns small={{ spacing: 'small' }}>
            <Column
              small={{
                spacing: 'small',
                size: 1,
              }}
            >
              <Text as="h1" weight={600} textAlign="center" noMargin>
                Your theme is ready!&nbsp;
                <span role="img" aria-label="Party Popper">
                  🎉
                </span>
              </Text>
              <Text as="h3" weight="normal" textAlign="center">
                Now you can easily use your very own theme to design apps and websites
              </Text>
            </Column>

            <Column
              small={{
                spacing: 'small',
                size: 1,
              }}
              medium={{
                alignItems: 'center',
              }}
            >
              {buttonState === 'initial' && (
                <ButtonGroup>
                  <Button
                    as="link"
                    variant="secondary"
                    label="Export to code"
                    url={data?.result.signupWithExportUrl}
                  />
                  <Button
                    as="button"
                    variant="accent"
                    label="Design with this theme"
                    onClick={() => setButtonState('design')}
                  />
                </ButtonGroup>
              )}

              {buttonState === 'design' && (
                <ButtonGroup>
                  <Button
                    as="button"
                    variant="secondary"
                    label=""
                    onClick={() => setButtonState('initial')}
                  >
                    <Icon liga="arrow_left" size={1} />
                  </Button>

                  <Button
                    as="link"
                    variant="accent"
                    label="Design mobile app"
                    url={data?.result.signupMobileUrl}
                  />

                  <Button
                    as="link"
                    variant="accent"
                    label="Design desktop app"
                    url={data?.result.signupDesktopUrl}
                  />
                </ButtonGroup>
              )}
            </Column>
          </Columns>

          <ResultContainer>
            <ResultPreview
              desktopUrl={data.result.desktopPreviewUrl}
              mobileUrl={data.result.mobilePreviewUrl}
            />
          </ResultContainer>

          <Columns small={{ spacing: 'small' }}>
            <Column
              small={{
                spacing: 'small',
                size: 1,
                paddingBottom: 'large',
              }}
              medium={{
                spacing: 'small',
                size: 7 / 12,
                justifyContent: 'flex-end',
                paddingBottom: undefined,
              }}
              large={{
                spacing: 'small',
                size: 5 / 12,
                justifyContent: 'flex-end',
                paddingBottom: undefined,
              }}
            >
              <SocialSharePanel
                shareUrl={'https://themegenerator.io'}
                tweets={[
                  'Generate themes and extract design tokens from any source! __LINK__ #designSystem #UIdesign',
                ]}
              />
            </Column>

            <Column
              small={{
                spacing: 'small',
                size: 1,
              }}
              medium={{
                spacing: 'small',
                size: 5 / 12,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
              large={{
                spacing: 'small',
                size: 7 / 12,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                as="link"
                label="Try another"
                variant="secondary"
                url="/"
              />
            </Column>
          </Columns>
          <Spacer small={{ spacing: 'large' }} />
        </div>
      )}
    </React.Fragment>
  );
});

export default Result;
