import { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';

import { useApiPaths } from '../../../hooks/useApiPaths';

export const useStyleExtraction = (location: PageProps['location']) => {
  const apiPaths = useApiPaths();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | undefined>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    setLoading(true);
    setData(undefined);
    setError(undefined);

    const url = new URL(location.href);
    const token = url.searchParams.get('t');

    const getStatus = async () => {
      try {
        const response = await fetch(
          apiPaths.getStyleExtractionUrl(token as string)
        );
        const responseJson = await response.json();

        setLoading(false);
        setData(responseJson);

        if (responseJson.status.state === 'running') {
          // Check status again in 2 seconds
          setTimeout(() => getStatus(), 2 * 1000);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError('An unexpected error occured, please try again');
      }
    };

    // Start checking status
    getStatus();
  }, [location]);

  return {
    loading,
    data,
    error,
  };
};
