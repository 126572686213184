import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../components/Layout';
import Meta from '../components/Meta';
import Navbar from '../components/Navbar';
import Main from '../components/Main';
import Result from '../components/Result';
import Footer from '../components/Footer';

export interface ThemeRouteProps extends PageProps {}

const ThemeRoute: React.FC<ThemeRouteProps> = React.memo(({ location }) => {
  return (
    <Layout>
      <Meta title="Your Theme" description="Amazing extracted theme" />
      <Navbar />
      <Main column>
        <Result location={location} />
      </Main>
      <Footer />
    </Layout>
  );
});

export default ThemeRoute;
