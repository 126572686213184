import React from 'react';
import { Theme } from '../../theme';

import { Columns as StyledColumns, Column as StyledColumn } from './styles';

export type ColumnConfigurationType = {
  spacing?: keyof Theme['spacing'];
  paddingBottom?: keyof Theme['spacing'];
  size?: number;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
};

export interface ColumnProps {
  small?: ColumnConfigurationType;
  medium?: ColumnConfigurationType;
  large?: ColumnConfigurationType;
  huge?: ColumnConfigurationType;
  children?: React.ReactNode | React.ReactNode[];
}

export type ColumnsConfigurationType = {
  spacing?: keyof Theme['spacing'];
};

export interface ColumnsProps {
  small?: ColumnsConfigurationType;
  medium?: ColumnsConfigurationType;
  large?: ColumnsConfigurationType;
  huge?: ColumnsConfigurationType;
  children?: React.ReactNode | React.ReactNode[];
}

export const Columns: React.FC<ColumnsProps> = ({
  small,
  medium,
  large,
  huge,
  children,
}) => (
  <StyledColumns small={small} medium={medium} large={large} huge={huge}>
    {children}
  </StyledColumns>
);

export const Column: React.FC<ColumnProps> = ({
  small,
  medium,
  large,
  huge,
  children,
}) => (
  <StyledColumn small={small} medium={medium} large={large} huge={huge}>
    {children}
  </StyledColumn>
);
